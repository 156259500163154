import { createTheme } from "@material-ui/core/styles"

const bleu = "#202C59"
const bleuLight = "#4d5486"
const bleuDark = "#00002f"
const orange = "#D95D39"
const orangeLight = "#ff8d65"
const orangeDark = "#a12d0e"
const white = "#fff"
const black = "#1D2424"
const clair = "#EBE4D6"

const theme = createTheme({
    palette: {
        primary: {
            main: bleu,
            light: bleuLight,
            dark: bleuDark
        },
        secondary: {
            main: orange,
            light: orangeLight,
            dark: orangeDark
        },
        common: {
            white,
            black,
            clair
        },
        text: {
            secondary: "#000"
        }
    },
    typography: {
        h1: {
            fontSize: "1.3333rem",
            fontFamily: "Bebas Neue",
            fontWeight: "normal",
            // color: white
        },
        // h2: {
        //    fontSize: "1.16666rem",
        //    fontFamily: "Open Sans",
        //    fontWeight: 700,
        //    color: bleu
        // },
        // h3: {
        //     fontSize: "0.9999rem",
        //     fontFamily: "Open Sans",
        //     fontWeight: 700,
        //     color: bleu
        //  },
        body1: {
            fontSize: "0.91666rem",
            fontFamily: "Franklin Gothic Medium",
            fontWeight: "normal"
        },
        body2: {
            fontSize: "0.91666rem",
            fontFamily: "Franklin Gothic Demi",
            fontWeight: "bold"
        }  
    },
    
})

export default theme